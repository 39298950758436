var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            size: "normal",
            title: "预览入职登记表",
            "mask-closable": false,
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("div", { staticClass: "box" }, [
            !_vm.isShow
              ? _c("img", {
                  attrs: { src: "data:image/jpeg;base64," + _vm.url, alt: "" },
                })
              : _vm._e(),
            _c("div", [_vm._v("扫码预览入职登记表")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }